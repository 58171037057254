export const themeSettings = {
  colors: {
    primary: '#FEF13C',
    // secondary: '#475F72',
    secondary: '#59768f',
    tertiary: 'teal',
    quaternary: '#FFFFFF',

    primary2: '#045CAC',
    primary3: '#3d6564',
    secondary2: '#28353F',

    text: '#1B1B1B',
    body: '#ffffff',
    body2: '#fafafa',
    white: '#FFFFFF',
    black: '#040607',

    fadedText: '#67666a',
    highlight: '#FFE07D',
    bright: '#F8C7CC',
    underline: '#247BA0',

    danger: '#DA2C38',
    warning: 'yellow',
    success: '#43cd80',
    grey: 'grey',
    white: '#fff',
    lightGrey: '#f8f8f8',
  },

  transitionSpeed: '400ms',
  fontFamily: 'VT Drone Ranger, Arial, sans-serif',
  fontFamily2: 'Basetica-Regular, Arial, sans-serif',
  fontSize: {
    small: '14px',
    medium: '16px',
    large: '18px',
    extraLarge: '24px',
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    bold: 700,
  },

  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  breakpoints: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  zIndex: {
    low: 1,
    medium: 5,
    high: 10,
    overlay: 100,
    modal: 1000,
  },
}
