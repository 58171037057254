import styled from 'styled-components'

export const H1Ref = styled.h1`
  font-size: 6rem;
  font-weight: 500;
  margin: 0;
  margin-top: 2rem;
  color: ${({ theme, color }) => color || theme.colors.primary};
  text-shadow: 0 0 4px ${({ theme }) => theme.colors.secondary2}, 0 0 4px ${({ theme }) => theme.colors.secondary2};

  @media screen and (max-width: 768px) {
    font-size: 4rem;
    text-align: left;
    margin-right: auto;
  }
`

// used here:
export const H1 = styled(H1Ref)`
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.2;
  font-family: 'VT DRONE RANGER', sans-serif;
  margin-top: 0;
`
