import { Hero } from './Hero'
import { JobOffers } from './JobOffers'
import { Layout } from './Style'

export const Careers = () => {
  return (
    <Layout>
      <Hero />
      <JobOffers />
    </Layout>
  )
}
