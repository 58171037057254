import { useEffect } from 'react'
import { services } from './data'
import AOS from 'aos'
import 'aos/dist/aos.css'
import styled from 'styled-components'
import { GridRow } from '../../../components/layouts/GridRow'

const Layout = styled.div`
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-bottom: 12vh;
  }
`

const H2 = styled.h2`
  text-align: left;
  margin-top: 10rem;
  font-size: 4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.secondary2};
  letter-spacing: 0.5px;
  margin-bottom: 6rem;
  margin-right: auto;
  margin-left: 3vw;

  @media screen and (max-width: 768px) {
    font-size: 3.3rem;
    margin-top: 5rem;
    margin-left: 0;
  }
`

const Services = styled.section`
  width: 100%;
  padding: 0 5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 0 1.6rem;
  }
`

const ServiceCard = styled.div`
  width: 100%;
  height: fit-content;

  margin: auto;
  padding: 4rem;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    padding: 0;
    min-height: unset;
  }
`

const ServiceTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.secondary2};
  letter-spacing: 0.5px;
  font-family: ${({ theme }) => theme.fontFamily};
`
const ServiceDescription = styled.p`
  font-size: 1.8rem;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    max-height: 268px;
  }
`

export const ServicesOverview = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Global animation duration (in milliseconds)
    })
  }, [])
  return (
    <Layout>
      <Services id='serviceOverview'>
        <H2>Notre offre de services</H2>
        {services.map((service, index) => (
          <GridRow col='2' mobile>
            <ServiceCard
              key={index}
              id={service.id}
              // Use the index to alternate animation directions and delay for a nice staggered effect
              data-aos={'fade-right'}
              data-aos-offset='200' // Trigger animation when the element is within 200px of the viewport
              data-aos-delay={`${index * 100}`} // Each card's animation delay increases by 100ms
              data-aos-once='true' // Animation will only happen once when scrolling down
            >
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceCard>
            <Img src={service.image} alt={service.title} idx={index} />
          </GridRow>
        ))}
      </Services>
    </Layout>
  )
}
