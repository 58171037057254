import styled, { css } from 'styled-components'

export const Popup = styled.div`
  position: fixed;
  bottom: 10px;
  /* right: 20px; */
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  /* right: 2%; */
  margin: 0;
  max-width: 1090px;
  background-color: ${(props) => props.theme.colors.primary || 'white'};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1000;
  font-size: 1.2rem;
  /* line-height: 1; */

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }
`

export const PopupContent = styled.div`
  padding: 20px;
  color: ${(props) => props.theme.colors.text || 'black'};

  h2 {
    margin-top: 0;
  }
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width};
  background: ${(props) => props.theme.colors.text || props.bgColor};
  color: ${(props) => props.theme.colors.body || 'white'};
  border-radius: 1rem;
  padding: 1.4rem 2rem;
  cursor: pointer;
  font-weight: bold;
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};

  &:nth-child(even) {
    /* color: #000; */
    background-color: transparent;
    color: ${(props) => props.theme.colors.text || 'black'};
  }

  ${(props) =>
    props.borderColor &&
    css`
      border: 1px solid ${(props) => props.borderColor};
    `}

  /* margin: auto 0rem; */
  /* border: 2px solid ${(props) => props.theme.colors.secondary}; */
  /* border-radius: 8px; */
  /* transition: ${({ theme }) => theme.transitionSpeed} all ease-in-out; */

  svg,
  i {
    margin-left: 0.4rem;
  }

  /* &:hover,
  &:active {
    color: ${(props) => props.theme.colors.secondary};
  } */
`

export const PopupActions = styled.div`
  margin-top: 20px;
  display: flex;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;

  /
`
