import styled from 'styled-components'

export const Layout = styled.div`
  max-width: 1150px;
  margin: 10vh auto;
  padding: 0 2rem;
  margin-bottom: 15vh;

  @media (max-width: 768px) {
    margin: 6vh auto;
  }
`

export const Content = styled.p`
  font-size: 1.8rem;
  line-height: 1.5;
  margin-bottom: 5rem;
  text-align: center;
`

export const Title = styled.h2`
  text-align: left;
  margin-top: 10rem;
  font-size: 4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.secondary2};
  letter-spacing: 0.5px;
  margin-bottom: 6rem;
  margin-right: auto;
  margin-left: 3vw;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 3.3rem;
    margin-top: 5rem;
    margin-left: 0;
  }
`

export const Img = styled.img`
  width: 20rem;
  height: 20rem;
  object-fit: contain;
  margin-right: 5rem;

  @media screen and (max-width: 768px) {
    width: 40%;
    margin-right: 2rem;
    margin-top: 2rem;
  }
`
