import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Hamburger, TopHeader, BottomHeader, HeaderContainer, List, NavLink, CloseIcon, Logo, CTAButton, Menu, Row, HeaderShape, YellowLight, LogoLong } from './Style'
import { headerLinks as defaultHeaderLinks } from './headerConfig'
import { Trans, useTranslation } from 'react-i18next'

import logo from '../../media/img/cyd-logo-text.svg'
import logo2 from '../../media/img/cyd-logo-vert.png'

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [visible, setVisible] = useState(true)
  const [headerLinks, setHeaderLinks] = useState(defaultHeaderLinks)

  const navigate = useNavigate()
  const location = useLocation()

  const { t } = useTranslation('common')

  const [animate, setAnimate] = useState(false)
  const menuRef = useRef(null)
  const hamburgerRef = useRef(null)

  useEffect(() => {
    const triggerAnimation = () => {
      setAnimate(true)
      setTimeout(() => setAnimate(false), 2000) // Match animation duration
    }

    // Immediately invoke the first animation
    triggerAnimation()

    // Set an interval for subsequent animations
    const intervalId = setInterval(triggerAnimation, 10000) // 10 seconds + animation duration

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && hamburgerRef.current && !hamburgerRef.current.contains(event.target)) {
        setMenuOpen(false)
      }
    }

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuOpen])

  return (
    <HeaderContainer isVisible={visible}>
      <TopHeader>
        {/* <YellowLight className={animate ? 'animate' : ''} /> */}
        <HeaderShape>
          <Logo
            src={logo}
            alt='Logo'
            onClick={() => {
              navigate('/')
              setMenuOpen(false)
            }}
          />
        </HeaderShape>
        <Row>
          {/* <CTAButton>Demander une soumission</CTAButton> */}
          <Hamburger ref={hamburgerRef} onClick={() => setMenuOpen(!menuOpen)}>
            <div></div>
            <div></div>
            <div></div>
          </Hamburger>
          {/* <LanguageSwitcher onClick={() => switchLanguage('en')} /> */}
        </Row>
      </TopHeader>

      <Menu ref={menuRef} open={menuOpen}>
        <CloseIcon onClick={() => setMenuOpen(false)}>
          <i className='fa-solid fa-times'></i>
        </CloseIcon>
        <List>
          {headerLinks.map(({ path, key }) => (
            <li key={path}>
              <NavLink to={path} onClick={() => setMenuOpen(false)}>
                {t(key)}
              </NavLink>
            </li>
          ))}
        </List>
        <LogoLong
          src={logo2}
          alt='Logo'
          onClick={() => {
            navigate('/')
            setMenuOpen(false)
          }}
        />
      </Menu>
    </HeaderContainer>
  )
}
