import styled from 'styled-components'
import { Row } from '../../../components/layouts/Row'
import { GridRow } from '../../../components/layouts/GridRow'

export const DescriptionCenter = styled.p`
  text-align: left;
  font-size: 2rem;
  color: white;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 2rem;
  }
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 10rem;
  margin-top: 15vh;
  background: ${({ theme }) => theme.colors.secondary};
  background: #28353f;
  margin: 0 auto;
  min-height: 50vh;
  padding: 0 5rem;

  @media screen and (max-width: 768px) {
    margin: 0 2rem;
    padding: 4rem 2rem;
  }
`

export const ColorEmphasis = styled.span`
  color: ${({ theme }) => theme.colors.primary2};
`

export const Title = styled.h2`
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  font-family: ${({ theme }) => theme.fontFamily};
  color: white;
  padding: 0 2rem;
  width: fit-content;
  margin: 0 auto;

  .colored {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 768px) {
    font-size: 3.3rem;
    text-align: left;
    margin-right: auto;
    width: 100%;
    padding: 0;
  }
`

export const GeneralDescription = () => {
  const description = (
    <>
      Notre mission est claire : transformer chaque maison en un chez soi de confort, de qualité, et de durabilité. Chez Construction Youri Durand, chaque clou, chaque planche, chaque design est pensé
      pour surpasser vos attentes et redéfinir ce que signifie "être chez soi".
    </>
  )
  return (
    <Layout>
      <GridRow col='2' mobile>
        <Title>
          REDÉFINIR <br /> ce que signifie
          <br />
          <span className='colored'>« être chez soi »</span>
        </Title>
        <DescriptionCenter>{description}</DescriptionCenter>
      </GridRow>
    </Layout>
  )
}
