import styled from 'styled-components/macro'
import { Subheading as SubheadingRef, H1 as H1Ref } from '../Hero/Style.js'
import { Col as ColRef } from '../../../components/layouts/Col'

export const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: ${({ theme }) => theme.colors.primary};

  position: relative;

  @media (max-width: 768px) {
    padding: 0 1.6rem;
  }
`

export const StyleImageSvgOverlay = styled.div`
  position: absolute;
  /* display: inline-block; */
  height: 100vh;
  z-index: 0;
  display: flex;
  /* opacity: 0.8; */
  right: 2rem;

  img {
    /* display: block; // Ensures there's no extra space underneath the image */
    width: 100%; // Adjust based on your specific size requirements
    height: 100%; // Keeps the image aspect ratio intact
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: rgba(255, 255, 255, 0.5); // Example: white overlay with 50% opacity
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export const ImageSvgOverlay = ({ imgSrc, svgSrc }) => {
  return (
    <StyleImageSvgOverlay>
      <img src={imgSrc} alt='Descriptive Alt Text' />
      {/* <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
        <use href={svgSrc} />
      </svg> */}
    </StyleImageSvgOverlay>
  )
}

export const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 90%;
  min-height: 55vh;
  margin: 1rem auto;
  opacity: 0.9;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

// Styled component for main title
export const H2 = styled.h2`
  color: #333;
  margin-bottom: 15px;
`

// Styled component for subtitle
export const SubH = styled.h3`
  color: #555;
  margin-bottom: 20px;
`

// Styled component for customer pain points
export const PainPoints = styled.div`
  color: #007bff;
  font-weight: bold;
  margin-bottom: 2.5rem;
  text-align: left;
  font-size: 1.65rem;
  line-height: 2;

  @media (max-width: 768px) {
    text-align: left;
    font-size: 1.6rem;
  }
`

export const Col = styled(ColRef)`
  padding: 0 2rem;
  width: 100%;
  height: 100%;

  > * {
    color: #1b75bc;
  }
`

export const H1 = styled(H1Ref)`
  font-size: 6rem;
  margin-top: 0;

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`

export const Subheading = styled(SubheadingRef)`
  font-size: 2.1rem;

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
`

/// IN BP

export const CenteredButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.3s;
  margin: 0 auto;
  display: block;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #0056b3;
  }
`
