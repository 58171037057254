import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageLayout, Title, Content } from './Style'
import { Hero } from './Hero'
import { MainContent } from './MainContent'
import { Helmet } from 'react-helmet'

export const About = () => {
  const { t } = useTranslation('about')

  return (
    <PageLayout>
      <Helmet>
        <title>{t('hero.titlePart1') + t('hero.titlePart2')} | Construction Youri Durand</title>
        <meta name='description' content={t('mainContent.description')} />
        <meta
          name='keywords'
          content='construction neuve, rénovation majeure, construction durable, entrepreneur général Lanaudière, engagement qualité, co-création, développement durable, achat local, recyclage construction, optimisation déplacements'
        />
      </Helmet>
      {/* <Title>{t('header')}</Title>
      <Content>{t('content')}</Content> */}
      <Hero />
      <MainContent />
    </PageLayout>
  )
}
