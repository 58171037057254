import { useTranslation } from 'react-i18next'
import { Content, Layout, Title, Img } from './Style'

import logo1 from '../../../app/media/img/logo-apchq.png'
import logo2 from '../../../app/media/img/logo-gcr.svg'
import logo3 from '../../../app/media/img/ecoLogo.png'

import { Row } from '../../../components/layouts/Row'

export const Expertise = () => {
  const { t } = useTranslation('home')
  return (
    <Layout>
      <Title>{t('expertise.title')}</Title>
      <Content>{t('expertise.content')}</Content>

      <Row justify='center' margin='auto' width='fit-content'>
        <Img src={logo1} />
        <Img src={logo2} />
        <Img src={logo3} />
      </Row>
    </Layout>
  )
}
