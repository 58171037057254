import styled from 'styled-components'
import { Hero } from './Hero'
import { GeneralDescription } from './GeneralDescription'
import { ServicesOverview } from './ServicesOverview'
import { CTASecondary } from './CTASecondary'
import { Helmet } from 'react-helmet'
import { ImageAd1 } from './ImageAd/ImageAd1'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Expertise } from './Expertise'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Homepage = () => {
  const location = useLocation()
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location])

  return (
    <Layout>
      <Helmet>
        <title>Construction Youri Durand | Entrepreneur général</title>
        <meta
          name='description'
          content="Construction Youri Durand - Votre partenaire de confiance pour des projets de construction neuve, rénovations majeures, et écohabitation. Découvrez notre engagement pour la qualité et l'innovation dans chaque construction."
        />
        <meta
          name='keywords'
          content='construction neuve, rénovation majeure, éco-construction, entrepreneur général Lanaudière, construction durable, innovation construction, qualité construction, construction haut de gamme Lanaudière, entrepreneur fiable, construction éco-responsable'
        />
      </Helmet>

      <Hero />
      <GeneralDescription />
      {/* <SocialProof /> */}
      <ServicesOverview />
      <ImageAd1 />
      <Expertise />
      {/* <VideoAd /> */}
      {/* <ProjectsOverview /> */}
      <CTASecondary />
    </Layout>
  )
}

export default Homepage
