import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import GlobalStyle from './theme/global-styles'
import { Header } from './theme/header/index'
import { ScrollToTop } from '../utils/routing/ScrollToTop'

// import { PrivateRoute } from '../utils/api/routing/PrivateRoute'
import { setAuthToken } from '../utils/api'
import useAuthStore from '../features/auth/authStore'
import { Homepage } from '../pages/Home'
import styled from 'styled-components'

import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Careers } from '../pages/Careers'
import { About } from '../pages/About'
import PrivacyPolicyPopup from '../features/PrivacyPolicyPopup'
import PrivacyPolicy from '../pages/PrivacyPolicy'

const Home = lazy(() => import('../pages/Home'))

const Contact = lazy(() => import('../pages/Contact'))
// const RealisationsPage = lazy(() => import('../pages/Realisations'))
const ClientLogin = lazy(() => import('../pages/ClientLogin'))
const Footer = lazy(() => import('./theme/footer'))

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`

const App = () => {
  const [loading, setLoading] = useState(false)
  const [animationClass, setAnimationClass] = useState('')

  const [menuOpened, setMenuOpened] = useState(false)

  const { checkAuthentication, isAuthenticated, loaded, setNoAuth, setUserMode } = useAuthStore()

  useEffect(() => {
    const storedToken = localStorage.getItem('token')
    const minimumDisplayTime = Math.random() * (2.7 - 2.1) + 2.1
    const displayTimePromise = new Promise((resolve) => setTimeout(resolve, minimumDisplayTime * 1000))

    const loadContentAsync = async () => {
      if (storedToken) {
        setAuthToken(storedToken)
        await checkAuthentication()
      }
      // Add other asynchronous loading logic here if necessary
    }

    Promise.all([loadContentAsync(), displayTimePromise]).then(() => {
      setAnimationClass('fadeOut') // Fade out the preloader
      setTimeout(() => {
        setLoading(false)
        setAnimationClass('fadeIn') // Once preloader is hidden, fade in the app content
      }, 500) // This timeout should match the fadeOut animation duration
    })
  }, [])

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <PrivacyPolicyPopup />

      <TransitionGroup component={null}>
        {loading ? (
          <CSSTransition key='preloader' timeout={500} classNames='fade'>
            {/* <Preloader /> */}
            <div></div>
          </CSSTransition>
        ) : (
          <CSSTransition key='app' timeout={500} classNames='fade'>
            <AppContainer>
              <Header setMenuOpened={setMenuOpened} />
              <Suspense fallback={<div></div>}>
                <Routes>
                  <Route path='/' element={<Homepage menuOpened={menuOpened} setMenuOpened={setMenuOpened} />} />
                  <Route path='/a-propos' element={<About />} />
                  {/* <Route path='/nos-realisations' element={<RealisationsPage />} /> */}
                  <Route path='/carrieres' element={<Careers />} />
                  <Route path='/contact' element={<Contact />} />
                  <Route path='/app/login' element={<ClientLogin />} />
                  <Route path='/politique-de-confidentialite' element={<PrivacyPolicy />} />

                  {/*<Route path='/app/dashboard' element={<PrivateRoute requiredAccountType='ADMIN'>{/* </PrivateRoute>} /> */}
                </Routes>
              </Suspense>
              <Footer />
            </AppContainer>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

export default App
