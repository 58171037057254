import React from 'react'
import { useTranslation } from 'react-i18next'
// import { Layout, Card, Col, Title, Subheading, Button } from './UIComponents' // Example imports
import { PainPoints, CenteredButton, Layout, Card, Col as ColRef, H1, Subheading, ImageSvgOverlay } from './Style'

import { GridRow } from '../../../components/layouts/GridRow'
import styled from 'styled-components'
import AnimatedButton from '../../../components/elements/Button/AnimatedButton'

import imgOverlay from '../../../app/media/img/imageOverlay.svg'
import { useNavigate } from 'react-router-dom'

const Title = styled.h2`
  font-size: 4rem;
  font-weight: 600;
  width: 80%;
  color: ${({ theme }) => theme.colors.secondary2};

  @media (max-width: 768px) {
    width: 100%;
  }
`

const Col = styled(ColRef)`
  width: 80%;
  max-width: 40vw;
  margin: 0 auto;

  height: 50vh;
  padding: 0 0.15rem;

  &:nth-child(1) {
    margin-right: 0;
  }

  &:nth-child(2) {
    margin-left: 0;
    > div {
      background: ${({ theme }) => theme.colors.primary};
      color: white !important;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 2rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  color: black;
  width: 100%;
  height: 100%;
  /* max-width: 40vw; */
  margin: 0 auto;
  text-align: center;
`

const BlockTitle = styled.h3`
  font-weight: 600;
  font-size: 3rem;
  margin-top: 1rem;

  .small {
    font-size: 2rem;
    font-weight: 400;
  }
  .italic {
    font-style: italic;
  }

  &:nth-of-type(2) {
    color: white !important;
    background: purple;
  }
`

const Text = styled.p`
  color: ${({ theme }) => theme.colors.secondary2};
  font-size: 1.8rem;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const Button = styled.button`
  background: white;
  width: 100%;
  /* padding: 1rem 2rem; */
  font-size: 1.85rem;
  font-weight: bold;
  height: 7.5rem;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.lightGrey};
  border-top: 10px solid ${({ theme }) => theme.colors.white};
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: white;
    transition: 0.3s;
  }

  &:nth-child(1) {
    &:hover {
      background: white;
      color: ${({ theme }) => theme.colors.primary};
      transition: 0.3s;
    }
  }

  &:nth-of-type(2) {
    background: ${({ theme }) => theme.colors.primary};
    color: white;
  }
`

const OuterText = styled.p`
  font-size: 2.4rem;
  width: 60%;
  text-align: center;
`

export const AnimatedButton2 = styled(AnimatedButton)`
  color: ${({ theme }) => theme.colors.secondary2};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const CTASecondary = () => {
  const { t } = useTranslation('ctasecondary')
  const navigate = useNavigate()

  return (
    <Layout>
      <ImageSvgOverlay imgSrc={imgOverlay} />
      <GridRow col='2' gap='1rem' mobile>
        <Col>
          <Title>Vous avez un projet ?</Title>
          <Text>
            Construction Youri Durand inc. travaille en étroite collaboration avec ses fournisseurs et sous-traitants, votre firme d'architecte et/ou bureau de design. Présents à toutes les étapes de
            votre projet pour un suivi optimal, nous vous informons et vous conseillons sur les meilleures options possibles.
          </Text>
          <GridRow col='2' gap='1rem' mobile>
            {/* <Button>Go</Button>
            <Button empty>Po</Button> */}
            <AnimatedButton
              fillColor='#FEF13C'
              baseColor='#28353F'
              onClick={() =>
                //  navigate('/contact')
                window.open('mailto:info@constructionyouridurand.com')
              }>
              Contactez-nous
            </AnimatedButton>
            <AnimatedButton2 fillColor='#28353F' baseColor='transparent' color='#FFF352' onClick={() => navigate('/a-propos')}>
              À propos de nous
            </AnimatedButton2>
          </GridRow>
        </Col>
      </GridRow>
    </Layout>
  )

  // return (
  //   <Layout>
  //     <Card>
  //       <Col>
  //         <H1>{t('title')}</H1>
  //         <Subheading>{t('subheading')}</Subheading>
  //       </Col>
  //       <Col>
  //         <PainPoints>
  //           {t('painPoint1')}
  //           <br />
  //           {t('painPoint2')}
  //           <br />

  //           <br />
  //           {t('painPoint4')}
  //           <br />
  //           {t('painPoint5')}
  //         </PainPoints>
  //         <CenteredButton to='/contact'>{t('buttonText')}</CenteredButton>
  //       </Col>
  //     </Card>
  //   </Layout>
  // )
}
