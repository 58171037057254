import { useTranslation } from 'react-i18next'
import { Layout, Content, Title } from './Style'

export const MainContent = () => {
  const { t } = useTranslation('about')
  return (
    <Layout>
      <Title>{t('mainContent.title')}</Title>
      <Content>{t('mainContent.description')}</Content>
    </Layout>
  )
}
