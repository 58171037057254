import image1 from '../../../app/media/img/service1.jpeg'
import image2 from '../../../app/media/img/service2.jpg'
import image3 from '../../../app/media/img/service3.jpeg'
import image4 from '../../../app/media/img/service4.jpeg'

export const services = [
  {
    id: 'constructionNeuve',
    title: 'Construction neuve : Bâtir le futur',
    description:
      "Chaque projet est une toile blanche. Spécialisés dans la construction neuve, nous travaillons à vos côtés pour transformer vos rêves en réalité. En mettant l'accent sur l'innovation et la durabilité, nos constructions neuves sont conçues pour se fondre harmonieusement dans leur environnement naturel tout en offrant tout le confort et la modernité que vous désirez. Des chalets prestigieux aux résidences modernes, chaque construction est un pas en avant vers un avenir plus vert.",
    image: image1,
  },
  {
    id: 'renovationsMajeures',
    title: "Rénovation majeure : redéfinir l'existant",
    description:
      'Nos services de rénovation majeure donnent une nouvelle vie à votre propriété. En utilisant des techniques et des matériaux éco-responsables, nous mettons à jour votre espace de manière significative tout en améliorant son efficacité énergétique et son impact environnemental. Que ce soit pour agrandir, moderniser, ou complètement transformer votre habitat, nos experts vous guident à chaque étape pour assurer que le résultat final dépasse vos attentes tout en préservant notre belle planète.',
    image: image2,
  },
  {
    id: 'commercialEtInstitutionnel',
    title: 'Commercial et institutionnel',
    description:
      "Notre expertise ne se limite pas aux résidences. Nous travaillons également avec des clients commerciaux et institutionnels pour créer des espaces qui inspirent et fonctionnent. En utilisant des matériaux durables et des techniques de pointe, nous concevons des bâtiments qui sont à la fois esthétiques et efficaces. Que vous ayez besoin d'un espace de bureau moderne, d'un magasin accueillant, ou d'une école fonctionnelle, nous sommes là pour vous aider à réaliser votre vision.",
    image: image3,
  },
  {
    id: 'ecoConstruction',
    title: 'Éco-Construction : Vivre en Harmonie',
    description:
      "S'aligner avec la nature n'a jamais été aussi élégant. Notre approche d'éco-construction combine design innovant et pratiques durables pour créer des espaces qui respectent la terre et enrichissent votre vie. En intégrant des systèmes de gestion de l'eau de pointe, comme le sac de filtration pour les eaux usées, et en privilégiant des matériaux locaux et renouvelables, nous vous offrons la possibilité de vivre dans un habitat qui est à la fois beau et bénéfique pour l'environnement.",
    image: image4,
  },
]
