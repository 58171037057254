import styled from 'styled-components'
import { H1Ref } from '../../../components/elements/H1'
import { Col as ColRef } from '../../../components/layouts/Col'

export const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('/img/hero-about.jpeg');
  background-size: cover;
  background-position: 0 60%;
  background-repeat: no-repeat;
  width: 100%;
  height: 110vh;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 6, 7, 0.69);
  }
`

// used here:
export const H1 = styled(H1Ref)`
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.2;
  font-family: 'VT DRONE RANGER', sans-serif;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
    text-align: left;
    margin-right: auto;
  }
`

export const Subheading = styled.span`
  display: block;
  font-size: 2.45rem;
  font-weight: 300;
  font-family: 'Basetica-Regular', sans-serif;
  margin: 0;
  margin-top: 2.5rem;
  color: ${({ theme }) => theme.colors.secondary};
  width: 90%;
  text-shadow: 0 0 4px ${({ theme }) => theme.colors.secondary2}, 0 0 4px ${({ theme }) => theme.colors.secondary2};

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 1.65rem;
    text-align: left;
    margin: 2rem 0;
    margin-right: auto;
  }
`

export const Col = styled(ColRef)`
  position: absolute;
  bottom: 0%;
  text-align: left;
  color: #ffffff;
  max-width: 75%;
  margin: 0 5vw;

  @media screen and (max-width: 768px) {
    max-width: 80vw;
    margin: 0 10vw;
    bottom: 0%;
  }
`
