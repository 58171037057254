import { useEffect } from 'react'
import { Col, H1, Layout, OverlayLogoMark, ServiceLink, ServicesContainer, Subheading, VerticalNumber } from './Style'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/elements/Button2'
import { TextColorEmphasis, TextColorEmphasis2 } from '../../../components/elements/TextColorEmphasis'
import { useNavigate } from 'react-router-dom'

export const Hero = () => {
  const { t } = useTranslation('home')
  const navigate = useNavigate()

  const services = ['constructionNeuve', 'renovationsMajeures', 'ecoConstruction']

  return (
    <Layout>
      <Col height='85vh' marginTop='15vh'>
        <H1>
          <TextColorEmphasis highlight>{t('hero.titlePart1')}</TextColorEmphasis>
          <br />
          <TextColorEmphasis2>{` ${t('hero.titlePart2')}`}</TextColorEmphasis2>
        </H1>
        <Subheading>{t('hero.subheading')}</Subheading>
        <ServicesContainer>
          {services.map((service, index) => (
            <>
              <ServiceLink key={index} onClick={() => navigate(`/#${services[index]}`)}>
                {t(`hero.services.${services[index]}`)}
                {console.log(t(`${services[index]}`))}
              </ServiceLink>
              {index < services.length - 1 && <span> |&nbsp;</span>}
            </>
          ))}
        </ServicesContainer>

        {/* <Button to='our-services'>{t('hero.button')}</Button> */}
      </Col>
      <VerticalNumber>RBQ 5777-9944-01</VerticalNumber>
      <OverlayLogoMark />
    </Layout>
  )
}
