import styled from 'styled-components'

export const Layout = styled.div`
  max-width: 1150px;
  margin: 10vh auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    margin: 6vh auto;
  }
`

export const Content = styled.p`
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 2rem;
`

export const Title = styled.h2`
  text-align: left;
  margin-top: 10rem;
  font-size: 3.65rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.secondary2};
  letter-spacing: 0.5px;
  margin-bottom: 6rem;
  margin-top: 2rem;
  margin-right: auto;
  /* margin-left: 3vw; */

  @media screen and (max-width: 768px) {
    font-size: 2.8rem;
    margin-top: 5rem;
    margin-left: 0;
  }
`
