import './i18n'
import React, { useEffect } from 'react'

import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './app'
import './app/media/fonts/fonts.css'
import { ThemeProvider } from './app/theme/ThemeProvider'
import { UIProvider } from './app/context/UIcontext'
import ReactGA from 'react-ga4'

const container = document.getElementById('root')
const root = createRoot(container)

// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE)

root.render(
  <ThemeProvider>
    <UIProvider>
      <Router>
        <App />
      </Router>
    </UIProvider>
  </ThemeProvider>
)
