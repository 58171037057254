import styled from 'styled-components'
import { Col as ColRef } from '../../../components/layouts/Col'

export const Col = styled(ColRef)`
  position: absolute;
  bottom: 0%; // Adjust based on your design needs
  /* left: 8%; */
  /* right: 5%; */
  text-align: left;
  color: #ffffff; // Assuming white text color for visibility
  max-width: 75%;
  margin: 0 5vw;

  @media screen and (max-width: 768px) {
    max-width: 90vw;
    margin: 0 auto;
    bottom: 0%;
  }
`

export const OverlayLogoMark = styled.div`
  width: 10rem;
  height: 10rem;
  background: url('/img/cydLogoMark_jaune.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  margin: 2rem;
  z-index: 1;

  @media screen and (max-width: 768px) {
    width: 6.5rem;
    height: 6.5rem;
    bottom: 1rem;
    right: 1rem;
  }
`

export const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('/img/hero-construction.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 6, 7, 0.59);
  }
`

// ref should come from boilerplate
export const H1Ref = styled.h1`
  font-size: 6rem;
  font-weight: 500;
  margin: 0;
  margin-top: 2rem;
  color: ${({ theme, color }) => color || theme.colors.primary};
  text-shadow: 0 0 4px ${({ theme }) => theme.colors.secondary2}, 0 0 4px ${({ theme }) => theme.colors.secondary2};

  @media screen and (max-width: 768px) {
    font-size: 3.1rem;
    text-align: center;
    /* margin-right: auto; */
  }
`

// used here:
export const H1 = styled(H1Ref)`
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.2;
  font-family: 'VT DRONE RANGER', sans-serif;
`

export const Subheading = styled.span`
  display: block;
  font-size: 2.45rem;
  font-weight: 300;
  font-family: 'Basetica-Regular', sans-serif;
  margin: 0;
  margin-top: 2.5rem;
  color: ${({ theme }) => theme.colors.secondary};
  width: 90%;
  text-shadow: 0 0 4px ${({ theme }) => theme.colors.secondary2}, 0 0 4px ${({ theme }) => theme.colors.secondary2};

  @media screen and (max-width: 768px) {
    font-size: 2.1rem;
    text-align: center;
    margin-top: 3.5rem;
    width: 80%;
    /* margin-right: auto; */
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
`

export const ServicesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0; // Adjust spacing as needed
  margin-top: 3.5rem;
  font-family: 'VT DRONE RANGER', sans-serif;
  color: #475f72;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.95rem;
  width: fit-content;
  text-shadow: 0 0 4px ${({ theme }) => theme.colors.secondary2}, 0 0 4px ${({ theme }) => theme.colors.secondary2};

  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
    text-align: left;
    flex-direction: column;
  }
`

export const ServiceLink = styled.div`
  cursor: pointer;
  transition: color 0.3s ease-in-out, text-shadow 0.3s ease-in-out;

  &:hover {
    color: yellow; // Change to the specific yellow you're using
    text-shadow: 0 0 4px ${({ theme }) => theme.colors.primary}10, 0 0 6px ${({ theme }) => theme.colors.primary}80;
  }

  &:not(:last-child) {
    margin-right: 10px; // Spacing between services, adjust as needed
  }

  @media screen and (max-width: 768px) {
    margin: 1rem auto;
  }
`

export const VerticalNumber = styled.div`
  position: absolute;
  bottom: 50%;
  transform: translateY(50%) rotate(-90deg);
  right: -2.2rem;
  width: fit-content;
  height: auto;
  color: ${({ theme }) => theme.colors.secondary};
  z-index: 0;
  /* transform: rotate(-90deg); */

  @media screen and (max-width: 768px) {
    right: -2.7rem;
    font-size: 1.4rem;
  }
`
