import { css, createGlobalStyle } from 'styled-components'

const fontFaces = css`
  /* @font-face {
    font-family: 'Basetica';
    src: url('Basetica.woff2') format('woff2'), url('Basetica.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Drone-Ranger-Pro-Bold';
    src: url('Drone-Ranger-Pro-Bold.woff2') format('woff2'), url('Drone-Ranger-Pro-Bold.woff') format('woff');
    font-display: swap;
  } */
`

const GlobalStyle = createGlobalStyle`
/* 
@font-face {
    font-family: 'Basetica';
    src: url('/fonts/Basetica.woff2') format('woff2'),
         url('/fonts/Basetica.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Drone-Ranger-Pro-Bold';
    src: url('/fonts/Drone-Ranger-Pro-Bold.woff2') format('woff2'),
         url('/fonts/Drone-Ranger-Pro-Bold.woff') format('woff');
    font-display: swap;
} */

${fontFaces}


*{
  box-sizing:border-box;
  -webkit-box-sizing: border-box;
}

#root{
  position:relative;
}

  html,
  body{
    scroll-behavior: smooth;
    background-color: ${({ theme }) => theme.colors.body};
    height: 100%;
    width: 100%;
    font-size: 62.5%;
    /* line-height: 1.5; */
    margin: 0;
    padding: 0;
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    

    /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  }


  h1,h2,h3,h4,h5,h6{
    font-family: ${({ theme }) => theme.fontFamily2};
    /* color: ${({ theme }) => theme.colors.text}; */
    letter-spacing:-0.95px;
    font-weight:100;
  }
  

  body {
    font-family:'Basetica-Regular', 'Inter', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 1.6em;
  }


/* // style resets */

  input{
    font-family:inherit;
  }

  button{
    border:0;
    background:transparent;
  }

  a{
    text-decoration:none;
    color: unset;
    margin:0;
  }

  // remove blue border focus
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }



  // general basic animations

  @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease forwards;
}

.fadeOut {
  animation: fadeOut 0.5s ease forwards;
}


// react transition group animations:

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}
 
`

export default GlobalStyle
