import React from 'react'
import styled from 'styled-components'

// Styled Components
const PrivacyPolicyContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 13vh;
  margin-bottom: 7vh;
  font-family: 'Work Sans', 'Arial', sans-serif;
`

const Heading = styled.h1`
  color: #333;
  text-align: center;
`

const Section = styled.section`
  margin-top: 20px;
`

const SubHeading = styled.h2`
  color: #666;
`

const Paragraph = styled.p`
  color: #444;
  line-height: 1.6;
`

const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  /* color: black; */
  text-decoration: none;
  font-size: 16px;

  &:hover {
    color: #c9302c;
  }
`

const DownloadIcon = styled.i.attrs({
  className: 'fas fa-file-pdf',
})`
  margin-right: 8px;
  font-size: 30px;
  color: black;
`

// Privacy Policy Component
const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyContainer>
      <Heading>Politique de confidentialité</Heading>
      {/* <i class='fa-solid fa-file-pdf'></i> */}
      {/* <Section>
        <SubHeading>Introduction</SubHeading>
        <Paragraph>
          Welcome to SOR SEO. This privacy policy outlines how we handle your personal information to provide you with our services while respecting your privacy. We value the trust you place in us
          and commit to protecting your data with the utmost care.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Information Collection</SubHeading>
        <Paragraph>
          We collect various types of information, including but not limited to your name, contact details, and payment information, when you register or place an order on our site. This information
          is essential to deliver our services effectively and enhance your user experience.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Use of Your Information</SubHeading>
        <Paragraph>
          The information we collect is used to process transactions, manage your account, improve our website functionality, and communicate with you regarding our services. We may also use your data
          to personalize your experience or send periodic emails with updates and offers.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Information Protection</SubHeading>
        <Paragraph>
          We implement a variety of security measures to maintain the safety of your personal information. Your data is stored in secure networks and is only accessible by a limited number of persons
          with special access rights, who are required to keep the information confidential.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Cookie Usage</SubHeading>
        <Paragraph>
          Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow). These cookies enable the site to recognize your
          browser and capture and remember certain information. We use cookies to help us remember and process the items in your shopping cart and understand and save your preferences for future
          visits.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Third-Party Disclosure</SubHeading>
        <Paragraph>
          We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties unless we provide you with advance notice. This does not include website hosting
          partners and other parties who assist us in operating our website, conducting our business, or serving you, so long as those parties agree to keep this information confidential.
        </Paragraph>
      </Section>

      <Section>
        <SubHeading>Consent</SubHeading>
        <Paragraph>By using our site, you consent to our privacy policy.</Paragraph>
      </Section> */}

      <DownloadLink href='https://static1.squarespace.com/static/5eaae45e6cb6d63ea8ecfb2b/t/663a55e4518d1018b1c34f0b/1715099108716/Politique+de+confidentialite%CC%81+2024.pdf' download>
        <DownloadIcon />
        Télécharger la politique de confidentialité
      </DownloadLink>
    </PrivacyPolicyContainer>
  )
}

export default PrivacyPolicy
