import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Popup, PopupContent, PopupActions, CloseButton, Button } from './Style' // Ensure you have PopupActions and CloseButton styled components defined

export const PrivacyPolicyPopup = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [cookies, setCookie] = useCookies(['user-consent'])

  useEffect(() => {
    if (!cookies['user-consent']) {
      setIsVisible(true)
    }
  }, [cookies])

  const handleAccept = () => {
    setCookie('user-consent', 'accepted', { path: '/', maxAge: 2592000 }) // Expires in 30 days
    setIsVisible(false)
  }

  const handleRefuse = () => {
    // Implement what should happen when user refuses
    setIsVisible(false)
  }

  if (!isVisible) return null

  return (
    <Popup>
      <PopupContent>
        <CloseButton onClick={() => setIsVisible(false)}>✕</CloseButton>
        <h2>Bienvenue sur notre site!</h2>
        <p>
          Nous utilisons des cookies pour améliorer votre expérience utilisateur et pour analyser le trafic de notre site. En continuant à utiliser notre site, vous acceptez notre utilisation des
          cookies.
        </p>
        <p>
          Pour plus d'informations, veuillez consulter notre{' '}
          <a href='/politique-de-confidentialite' style={{ textDecoration: 'underline' }}>
            politique de confidentialité
          </a>
          .
        </p>
        <PopupActions>
          <Button onClick={handleAccept} style={{ marginRight: '10px' }}>
            Accepter
          </Button>
          <Button onClick={handleRefuse}>Refuser</Button>
        </PopupActions>
      </PopupContent>
    </Popup>
  )
}

export default PrivacyPolicyPopup
