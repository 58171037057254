import styled, { keyframes } from 'styled-components'

// Define keyframes for the fill animation
const fillAnimation = keyframes`
  from {
    background-size: 100% 0;
  }
  to {
    background-size: 100% 100%;
  }
`

// Styled button with animation and custom properties
const AnimatedButton = styled.button`
  background: ${({ baseColor, theme }) => baseColor || theme.colors.lightGrey};
  width: 100%;
  font-size: 1.85rem;
  font-weight: bold;
  height: 7.5rem;
  color: ${({ theme }) => theme.colors.primary};
  border: 1.5px solid ${({ theme }) => theme.colors.secondary};
  /* border-top: 10px solid ${({ theme }) => theme.colors.white}; */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  background-repeat: no-repeat;

  &:hover {
    background-image: ${({ fillColor }) => `linear-gradient(${fillColor}, ${fillColor})`};
    background-size: 100% 0;
    animation: ${fillAnimation} 0.3s forwards;
    color: ${({ theme }) => theme.colors.secondary2};
  }
`

export default AnimatedButton
