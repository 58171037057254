import React from 'react'
import styled from 'styled-components'

const FrameWrapper = styled.div`
  height: 782px;
  width: 100%;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    background: ${({ theme }) => theme.colors.secondary2};
    padding: 0 5rem;
    height: 100vh;
  }
`

const OverlapGroup = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
  object-fit: cover;

  @media (max-width: 768px) {
    display: none;
  }
`

const Vector = styled.img`
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
  object-fit: cover;

  @media (max-width: 768px) {
    display: none;
  }
`

const TextWrapper = styled.div`
  color: #fff426;
  font-family: 'VT Drone Ranger', Helvetica;
  font-size: 45px;
  font-weight: 500;
  position: absolute;
  top: 440px;
  right: 4vw;
  max-width: 55rem;
  /* transform: translateX(-50%); */
  text-align: right;
  line-height: 1.2;
  /* white-space: nowrap; */

  @media (max-width: 768px) {
    font-size: 2.5rem;
    top: 390px;
    white-space: normal;
  }
`

const PrecisionRigueur = styled.div`
  color: #fff426;
  font-family: 'VT Drone Ranger', Helvetica;
  font-size: 45px;
  font-weight: 500;
  position: absolute;
  top: 208px;
  right: 4vw;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    top: 130px;
    white-space: normal;
  }
`

const TextParagraph = styled.p`
  color: #ffffff;
  font-family: 'Basetica-Regular', Helvetica;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  /* left: 50%;
  transform: translateX(-50%); */
  right: 4vw;

  text-align: right;
  max-width: 55rem;
  white-space: normal;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const Div = styled(TextParagraph)`
  top: 263px;

  @media (max-width: 768px) {
    top: 160px;
  }
`

const VotreSecuriteEst = styled(TextParagraph)`
  top: 552px;
  max-width: 78rem;
  text-align: right;
  right: 4vw;

  @media (max-width: 768px) {
    top: 480px;
  }
`

export const Frame = () => {
  return (
    <FrameWrapper>
      <OverlapGroup>
        <Img alt='Background' src='/img/house.jpeg' />
        <Vector alt='Vector' src='/img/svgshapes.svg' />
        <PrecisionRigueur>PRÉCISION &amp; RIGUEUR</PrecisionRigueur>
        <Div>
          Dans les moindres détails, vous trouverez la marque de notre précision. Notre approche méticuleuse assure que chaque aspect de votre projet est réalisé avec la plus grande rigueur,
          garantissant une qualité sans compromis.
        </Div>
        <TextWrapper>DURABILITÉ, CONFORMITÉ et SÉCURITÉ</TextWrapper>
        <VotreSecuriteEst>
          Votre sécurité est notre priorité. C&#39;est pourquoi nous adhérons strictement aux normes les plus élevées de l&#39;industrie, assurant que chaque construction n&#39;est pas seulement
          esthétiquement plaisante, mais aussi durable et sécuritaire.
        </VotreSecuriteEst>
      </OverlapGroup>
    </FrameWrapper>
  )
}

export const ImageAd1 = () => {
  return <Frame />
}
