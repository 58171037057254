import { Col, H1, Layout, ServiceLink, ServicesContainer, Subheading } from './Style'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/elements/Button2'
import { TextColorEmphasis, TextColorEmphasis2 } from '../../../components/elements/TextColorEmphasis'

export const Hero = () => {
  const { t } = useTranslation('careers')

  return (
    <Layout>
      <Col height='85vh' marginTop='15vh'>
        <H1>
          <TextColorEmphasis highlight>{t('hero.titlePart1')}</TextColorEmphasis>
          <br />
          <TextColorEmphasis2>{` ${t('hero.titlePart2')}`}</TextColorEmphasis2>
        </H1>
        <Subheading>{t('hero.subheading')}</Subheading>
      </Col>
    </Layout>
  )
}
