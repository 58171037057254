import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(HttpBackend) // Enables loading of translation JSON files
  .use(LanguageDetector) // Enables detection of language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    fallbackLng: 'fr', // Default fallback language
    ns: ['home', 'about'], // Namespaces used in your project
    defaultNS: 'home',
    debug: true, // Set to true to see debug information in the console
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}/translation.json', // Path to your translation files
    },
    interpolation: {
      escapeValue: false, // React already safeguards from XSS
    },
    // Additional options can be added here
  })

export default i18n
