import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '../../../components/elements/Button/AnimatedButton'
import { animated, useSpring } from 'react-spring'

// Styled components for job offer details
const JobOffersContainer = styled.div`
  margin: 10vh auto;
  width: 50%;

  @media (max-width: 768px) {
    width: 90%;
  }
`

const SectionTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 1rem;
  font-family: ${({ theme }) => theme.fontFamily};

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`

const JobDetails = styled(animated.div)`
  margin-bottom: 1rem;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`

const JobTitle = styled.h3`
  font-size: 2.2rem;
  cursor: pointer;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : 'black')};
  font-family: ${({ theme }) => theme.fontFamily};

  &:hover {
    /* text-decoration: underline; */
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`

const JobDescription = styled.p`
  font-size: 1.8rem;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`

const ApplyButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
`

const Col = styled.div`
  height: ${({ height }) => height || 'auto'};
  margin: 0 auto;
  margin-top: ${({ marginTop }) => marginTop || '5rem'};
  max-width: 40rem;
`

const NoJobsMessage = styled.div`
  font-size: 1.8rem;
  text-align: center;
`

const JobTitleList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 3rem;
`

const ListItem = styled.div`
  font-size: 2.2rem;
  margin: 0.5rem 0;
  cursor: pointer;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : 'black')};
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`

const jobOffersData = [
  {
    id: 1,
    title: 'Charpentier-menuisier/charpentière-menuisière',
    details: [
      { label: 'Territoire', value: 'Lanaudière' },
      { label: 'Poste disponible', value: 'Dès maintenant' },
      { label: 'Acheminez votre CV via courriel', value: 'info@constructionyouridurand.com' },
    ],
    presentation: `
      Vous avez envie de contribuer à l’essor d’une jeune entreprise en construction? Voici une belle opportunité! Nous croyons que le développement de notre entreprise passe fondamentalement par une solide équipe de travail.

      Youri Lavallée-Durand est un entrepreneur général menant des projets de construction neuve, de rénovation résidentielle et commerciale. Notre entreprise rassemble des passionnés de la construction. Grâce à notre polyvalence, notre rigueur et notre approche novatrice, ainsi que par notre capacité de mener à bien des projets d’envergure, Construction Youri Durand inc. est l'une des jeunes entreprises du secteur en pleine expansion dans la région de Lanaudière.
    `,
    jobTitles: ['Charpentier-menuisier/charpentière-menuisière', 'Apprenti charpentier-menuisier/apprentie charpentière-menuisière'],
    employmentType: 'Temps Plein, Permanent',
    workingHours: '40 heures / semaine',
    training: 'Carte de compétence de compagnon / apprenti charpentier-menuisier en vigueur',
    language: 'Français',
    salary: 'Taux CCQ et prime selon expérience',
  },
  {
    id: 2,
    title: 'Électricien/Électricienne',
    details: [
      { label: 'Territoire', value: 'Montérégie' },
      { label: 'Poste disponible', value: 'Immédiat' },
      { label: 'Envoyez votre CV à', value: 'emplois@electrosolutions.com' },
    ],
    presentation: `
      Rejoignez notre équipe dynamique chez ElectroSolutions! Nous sommes à la recherche d’électriciens expérimentés pour rejoindre notre équipe en pleine croissance. Nos projets varient entre résidentiels et commerciaux, offrant des défis stimulants qui permettent de valoriser vos compétences.

      ElectroSolutions se distingue par son engagement envers la qualité et la sécurité sur tous nos chantiers. Si vous êtes prêt à travailler dans un environnement où votre travail est valorisé et où vous pouvez réellement faire une différence, envoyez-nous votre candidature!
    `,
    jobTitles: ['Électricien qualifié', 'Apprenti électricien'],
    employmentType: 'Temps Plein, Contractuel',
    workingHours: '35 heures / semaine',
    training: 'Certification d’électricien requise',
    language: 'Français',
    salary: 'Selon la convention collective',
  },
  {
    id: 3,
    title: 'Peintre en bâtiment',
    details: [
      { label: 'Territoire', value: 'Québec' },
      { label: 'Poste disponible', value: 'À partir de juin' },
      { label: 'Contactez', value: 'recrutement@paintexperts.com' },
    ],
    presentation: `
      PaintExperts, une entreprise leader dans le domaine de la peinture résidentielle et commerciale, cherche à agrandir son équipe avec des peintres professionnels. Nous offrons un environnement de travail respectueux et professionnel où la qualité est au cœur de nos préoccupations.

      Nos projets sont diversifiés, offrant à nos employés l'opportunité de travailler sur de nouvelles structures ainsi que sur des bâtiments historiques nécessitant un soin particulier. Si vous avez un bon coup de pinceau et une passion pour la peinture, PaintExperts est l'endroit idéal pour faire valoir vos talents.
    `,
    jobTitles: ['Peintre qualifié', 'Assistant peintre'],
    employmentType: 'Temps Plein, Temporaire',
    workingHours: '30 heures / semaine',
    training: 'Expérience en peinture requise',
    language: 'Français',
    salary: 'Compétitif selon expérience',
  },
]

const Job = ({ job, toggle, isOpen }) => {
  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { tension: 170, friction: 26 },
    reverse: !isOpen,
  })

  if (!isOpen) return null

  return isOpen ? (
    <>
      <JobDetails style={animationProps} key={job.id}>
        <JobTitle className='clickable'>{job.title}</JobTitle>
        {job.details.map((detail, index) => (
          <JobDescription key={index}>
            <strong>{detail.label}: </strong> {detail.value}
          </JobDescription>
        ))}
        <JobDescription>{job.presentation}</JobDescription>
        <br />
        <JobDescription>
          <strong>TITRE DES POSTES:</strong>
          {job.jobTitles.map((title, index) => (
            <span key={index}>
              {' '}
              {title}
              {index !== job.jobTitles.length - 1 && ','}
            </span>
          ))}
        </JobDescription>
        <br />
        <br />
        <JobDescription>
          <strong>TYPE D'EMPLOI:</strong> {job.employmentType}
        </JobDescription>
        <JobDescription>
          <strong>HORAIRE:</strong> {job.workingHours}
        </JobDescription>
        <JobDescription>
          <strong>FORMATION:</strong> {job.training}
        </JobDescription>
        <JobDescription>
          <strong>LANGUE:</strong> {job.language}
        </JobDescription>
        <JobDescription>
          <strong>SALAIRE:</strong> {job.salary}
        </JobDescription>
        <Col>
          <Button fillColor='#FEF13C' baseColor='#28353F' onClick={() => window.open('mailto:info@constructionyouridurand.com')}>
            Postulez ici
          </Button>
        </Col>
      </JobDetails>
    </>
  ) : null
}

export const JobOffers = () => {
  const [selectedJob, setSelectedJob] = useState(jobOffersData.length ? jobOffersData[0].id : null)

  useEffect(() => {
    // Set the first job as active by default if jobs are available
    if (jobOffersData.length) {
      setSelectedJob(jobOffersData[0].id)
    }
  }, [])

  const openEmail = () => {
    window.open('mailto:info@constructionyouridurand.com')
  }

  const toggleDetails = (id) => {
    setSelectedJob(selectedJob === id ? null : id)
  }

  return (
    <JobOffersContainer>
      <SectionTitle>Offres d'emploi</SectionTitle>
      <JobTitleList>
        {jobOffersData.length === 0 ? (
          <NoJobsMessage>Aucun poste disponible présentement.</NoJobsMessage>
        ) : (
          jobOffersData.map((job) => (
            <ListItem key={job.id} onClick={() => setSelectedJob(job.id)} isActive={selectedJob === job.id}>
              {job.title}
            </ListItem>
          ))
        )}
      </JobTitleList>
      <br />
      {jobOffersData.length === 0 ? (
        <NoJobsMessage>Aucun poste disponible présentement. Ceci dit nous sommes toujours à la recherche de talents!</NoJobsMessage>
      ) : (
        jobOffersData.map((job) => <Job key={job.id} job={job} toggle={() => toggleDetails(job.id)} isOpen={selectedJob === job.id} />)
      )}
    </JobOffersContainer>
  )
}
