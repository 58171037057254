import styled from 'styled-components'

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  color: #ccc;
  font-size: 2rem;
  font-weight: 600;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  margin: 20px;
`
